import React, { useEffect, useState } from 'react'
import ApplicationSelector from '../Devices/DeviceInstallation/ApplicationSelector'
import { CustomButton, DateTimeDisplay, DropDown, FormatNumber, Header, MultifilteredDataTable } from '../UiComponents';
import { getAuditLogColumns } from './logColumns';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { PREDEFINED_DATE_RANGE } from '../Events/utils';
import preDefinedDateRange from '../Events/utils/preDefinedDateRange';
import generateDateRange from '../Events/utils/generateDateRange';
import CustomDateRangeSelector from '../Events/CustomDateRangeSelector';
import ExportToCSV from '../UiComponents/ExportToCSV';
import { IFilter } from '../UiComponents/MultiFilter/types';
import formatDateTime from '../Events/utils/formatDateTime';

const GridController: React.FC<any> = ({
  columns,
  dateRangeSelected,
  customDateRange,
  setCustomDateRange,
  data,
  activeFilters,
  sortingSequence,
  externalFilters,
  gridControls,
  onDateRangeChange,
}) => {
  if (!data?.summary) {
    return null;
  }
  return (
    <>
      <Header
        route="auditLog"
        headerText={
          <>
            {`Total Counts: `}
            <strong>
              <FormatNumber number={data ? data.summary.total_msgs : 0} />
            </strong>
          </>
        }
      >
        <ExportToCSV
          columns={columns.filter((column: any) => column.exportable)}
          filter={[...externalFilters, ...activeFilters].map(
            ({ filterType, filterValue }: IFilter) => ({
              name: filterType,
              value: filterValue,
            })
          )}
          pageName="Audit Log"
          dataLookUp="audit_msgs"
          endpoint="audit-msgs-export"
          sortingInfo={
            sortingSequence.length > 0
              ? {
                sort_by_field: sortingSequence[0].name,
                sort_order: sortingSequence[0].order,
              }
              : {}
          }
          expectedRecordCount={data.summary.total_msgs}
        />

        <DropDown
          style={{
            margin: "0",
            position: "absolute",
            right: "5px",
            top: "42px",
          }}
          options={[
            { label: "Last 1 Hour", value: PREDEFINED_DATE_RANGE.LAST_1_HOUR },
            {
              label: "Last 3 Hours",
              value: PREDEFINED_DATE_RANGE.LAST_3_HOURS,
            },
            {
              label: "Last 5 Hours",
              value: PREDEFINED_DATE_RANGE.LAST_5_HOURS,
            },
            { label: "Custom", value: PREDEFINED_DATE_RANGE.CUSTOM },
          ]}
          value={dateRangeSelected}
          onChange={(dateRange: any) => {
            onDateRangeChange(dateRange);
          }}
        />
        {gridControls}
      </Header>
      {customDateRange.from !== null && (
        <div style={{ color: "white", marginLeft: "24px" }}>
          Showing Logs from{" "}
          <strong>
            <DateTimeDisplay dateTime={customDateRange.from} />
          </strong>{" "}
          to{" "}
          <strong>
            <DateTimeDisplay dateTime={customDateRange.to} />
          </strong>
          <CustomButton
            style={{ marginLeft: "8px", marginTop: "-4px" }}
            onClick={() => setCustomDateRange({ from: null, to: null })}
            variant="outlined-white"
          >
            Change
          </CustomButton>
        </div>
      )}
    </>
  );
};


function AuditLog() {
  const [customDateRange, setCustomDateRange] = useState({ from: null, to: null });
  const [dateRangeSelected, setDateRangeSelected] = useState(PREDEFINED_DATE_RANGE.LAST_1_HOUR);

  const { selectedApplication }: any = useApplicationContext();

  const getDateRangeFilter = (
    dateRangeSelected: string,
    customDateRange: any
  ) => {
    if (dateRangeSelected === PREDEFINED_DATE_RANGE.CUSTOM) {
      return customDateRange.from !== null
        ? [
          {
            filterType: "activity_datetime",
            filterValue: {
              from: formatDateTime(customDateRange.from),
              to: formatDateTime(customDateRange.to),
            },
          },
        ]
        : [];
    } else {
      return [
        {
          filterType: "activity_datetime",
          filterValue: generateDateRange(dateRangeSelected),
        },
      ];
    }
  };

  useEffect(() => {
    if (
      dateRangeSelected !== preDefinedDateRange.CUSTOM &&
      customDateRange.from !== null
    ) {
      setCustomDateRange({ from: null, to: null });
    }
  }, [dateRangeSelected]);

  const supplyFilters = getDateRangeFilter(dateRangeSelected, customDateRange);
  return (
    <div>
      <ApplicationSelector style={{ marginLeft: "1.8em" }} />
      {dateRangeSelected === PREDEFINED_DATE_RANGE.CUSTOM &&
        customDateRange.from === null && (
          <CustomDateRangeSelector
            onDone={(dateRange: any) => setCustomDateRange(dateRange)}
            onClose={() =>
              setDateRangeSelected(PREDEFINED_DATE_RANGE.LAST_1_HOUR)
            }
          />
        )}
      <MultifilteredDataTable
        endPoint="audit-msgs"
        dataLookupKey="audit_msgs"
        totalRecordsLookupKey="total_msgs"
        gridController={(props: any) => (
          <GridController
            columns={getAuditLogColumns()}
            dateRangeSelected={dateRangeSelected}
            onDateRangeChange={(dateRange: any) =>
              setDateRangeSelected(dateRange)
            }
            customDateRange={customDateRange}
            setCustomDateRange={setCustomDateRange}
            {...props}
          />
        )}
        columns={getAuditLogColumns()}
        selectedApplication={selectedApplication}
        externalFilters={supplyFilters}
        disabled={supplyFilters.length < 1}
        disableMultiSort
        defaultSortingSequence={[{ name: "activity_datetime", order: "desc" }]}
      />
    </div>
  )
}

export default AuditLog