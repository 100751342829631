export const DURATION_TOSTA = 5000;
export const DEFAULT_INSTALL_STATUS_VALUE = 10;
export const letterNumberRegXWithOutSpace = /^[0-9a-zA-Z_-]+$/;
export const letterNumberRegXWithSpace = /^[0-9a-zA-Z_-\s\.]*$/;
export const letterNumberTabluler = /^[0-9a-zA-Z_-|\s]*$/;
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const EXPIRE_LIMITED_DAYES_MAX = 90;
export const EXPIRE_LIMITED_DAYES = 30;
export const YELLOW_DAYS = 30;
export const RED_DAYS = 5;
export const YELLOW_DAYS_MESSAGE = `This device certificate is expiring within ${YELLOW_DAYS} days, please renew the certificate.`;
export const RED_DAYS_MESSAGE = `This device certificate is expiring within ${RED_DAYS} days, please renew the certificate.`;

export const CONFIG_TYPES = { LORIOT: "LORIOT" };
export const AUTH_TYPES = { X509: "X509" };

export const PAGES = {
  DEVICES: "devices",
  DEVICE_INSTALLATION: "device_installation",
};

export const installationStatusDropDownOptions = [
  { label: "Unknown", value: 10 },
  { label: "Installed", value: 30 },
  { label: "Uninstalled", value: 50 },
  { label: "Reviewed", value: 70 },
  { label: "Rejected", value: 90 },
  { label: "Operational", value: 110 },
  { label: "Maintenance", value: 130 },
];

export const OPERATION_STATUS= [
  {label: "Role Creation", value:'role_creation'},
  {label: "Role Updation", value:'role_updation'},
  {label: "Role Deletion", value:'role_deletion'},
  {label: "User Creation", value:'user_creation'},
  {label: "User Updation", value:'user_updation'},
  {label: "User Deletion", value:'user_deletion'},
  {label: "Device Config Creation", value:'device_config_creation'},
  {label: "Device Config Updation", value:'device_config_updation'},
  {label: "Device Config Deletion", value:'device_config_deletion'},
]
export type OperationStatus = "role_creation" | "role_updation"|
 "role_deletion"| "user_creation"| "user_updation"| 
 "user_deletion"| "device_config_creation"| "device_config_updation"| "device_config_deletion";

export const OPERATION_STATUS_LABEL:any = {
  role_creation: "Role Creation",
  role_updation: "Role Updation",
  role_deletion: "Role Deletion",
  user_creation: "User Creation",
  user_updation: "User Updation",
  user_deletion: "User Deletion",
  device_config_creation: "Device Config Creation",
  device_config_updation: "Device Config Updation",
  device_config_deletion: "Device Config Deletion"
}

export const ROWS_PER_PAGE = [10, 20, 50];

export const STATUS_TYPES = [
  {label: "Success", value:'Success'},
  {label: "Requested", value:'Requested'},
  {label: "Failure", value:'Failure'},
]
export const EVENT_TYPES = [
  "DEVICE_CONNECTED",
  "DEVICE_DISCONNECTED",
  "DEVICE_CREATED",
  "DEVICE_DELETED",
  "IOT_HUB_ALERT",
];

export const TOKEN_KEY = "sso_token";
export const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
export const DEFAULT_CERT_DOWNLOAD_ERROR_MESSAGE = "Error occured while downloading certificate. Please try again."
export const DEFAULT_ERROR_MESSAGE = "Something went wrong!";
export const DEFAULT_CERT_ERROR_MESSAGES:DefaultCertificateError = {
  download:"Error occured while downloading certificate. Please try again.",
  revoke:"Error occured while revoking certificate. Please try again.",
  renew:"Error occured while renewing certificate. Please try again.",
  default:"something went wrong"
}
export const LETTER_CHARACTER_LIMIT= 50;
export const CERT_RENEW_QUESTION = "Are you sure want to renew this device certificate";
export const CERT_DOWNLOAD_QUESTION = "Are you sure want to download this device certificate";

export enum TAG_TYPE {
  Text = "Text",
  Dropdown = "Dropdown",
  Tabular = "Tabular",
}

export enum TAG_DATA_TYPE {
  number = "number",
  text = "text",
}
export const DEFAULT_API_ERROR_MSG = "Error occured. Please try again after sometime."
export const DEFAULT_API_DELETE_MSG = "Error occured while performing delete operation."
export const INVALID_LEVEL_NAME_ERROR_MSG = "Invalid levelname (only characters and digits with pipe(|) allowed)"

export interface DefaultCertificateError{
  download: string;
  revoke:string;
  renew:string;
  default:string;
}
